import React from "react";
import Navbar from "./Components/Navbar/Navbar.js"
import Footer from "./Components/Footer/Footer.js"
import EmailForm from "./Components/EmailForm/EmailForm.js"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs"

// Redux 
import { Provider} from "react-redux";
import store from "./store";

function App() {

  return (
    <Provider store={store}>
    
      <Router>
      <Navbar />
      <Route exact path="/" component={Home} />
      <Switch>
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/form" component={EmailForm} />
      </Switch>

      <Footer />
    </Router>
     
    </Provider>   
  );
}

export default App;
import React, { useEffect } from "react";
import aboutUs from "../../Assets/aboutUs.png";
import "./AboutUs.css";
import "./AboutUs.mobile.css";

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

const AboutUs = () => {
  ScrollToTopOnMount();

  return (
    <>
      <div className="container">
        <div className="row aboutUs-space">
          <div className="col-md">
            <p className="aboutUs-page-title">ABOUT US</p>
            <p className="aboutUs-page-text">
              Mohd Azim General Trading LTD is the property of and operated by
              Mohd Azim General Trading LTD, registered in Saint Lucia under
              license number 2023-00245, with its registered address at Ground
              Floor, The Sotheby Building, Rodney Bay, Gross-Islet Saint Lucia.
              <br></br>
              Welcome to Mohd Azim General Trading LTD - Your Gateway to the
              Forex Market
              <br></br>
              <br></br>
              At Mohd Azim General Trading LTD, we are committed to providing
              our clients with the highest level of service and access to the
              global Forex market. Our team of experienced professionals is
              dedicated to offering personalized support and expert advice,
              ensuring that you have the tools and knowledge needed to succeed
              in the dynamic world of currency trading.
            </p>
          </div>
          <div className="col-md center">
            <img src={aboutUs} className="aboutUs-page-image"></img>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="aboutUs-page-text">
              <br></br>
              Why Choose Mohd Azim General Trading LTD?
              <br></br> - Technology: We offer a state-of-the-art trading
              platform that ensures fast, reliable, and secure transactions.
              <br></br> - Support: Our customer service team is available 24/5
              to answer any questions and provide assistance whenever you need
              it. <br></br> - Integrity: We operate with transparency and
              fairness, building trust with our clients through ethical trading
              practices.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="aboutUs-page-text">
              Our Services Include: <br></br> - Access to major and exotic
              currency pairs <br></br> - Secure and efficient account funding
              and withdrawal processes
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="aboutUs-page-text">
              Join Mohd Azim General Trading LTD today and experience the
              difference that professional brokerage services can make in your
              trading journey. <br></br>
              <br></br> Contact us to learn more about our services or to open
              an account. Your success is our priority, and we look forward to
              being a part of it. <br></br>
              <br></br> Trading Forex involves significant risk of loss and is
              not suitable for all investors. Please ensure that you fully
              understand the risks involved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;

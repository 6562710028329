import React from 'react'
import logo from "../../Assets/Logo.png"
import { Link} from "react-router-dom"
import "./Navbar.css"
import "./Navbar.mobile.css"

const Navigationbar = () => {
    return <div className= "container">
        <div className="row">
        <Link exact to="/"><img src={logo} className="logo"></img></Link>

            <div className="col">
                    <ul className="navBar-links">
                   
                        <li><Link exact to="/" className="navBar-link">Home</Link></li>
                        <li><Link exact to="/about-us" className="navBar-link">About Us</Link></li>
                        <li><Link exact to={{
                            pathname: "/form",
                            state: "Demo Account"
                        }}><button className="navBar-button">Start a demo account</button></Link></li>
                    </ul>
            </div>
        </div>
    </div>
}

export default Navigationbar;
import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../Assets/Logo.png";
import emailIcon from "../../Assets/emailIcon.png";
import facebook from "../../Assets/facebook.png";
import location from "../../Assets/location.svg";
import "./footer.css";
import "./Footer.mobile.css";

const Footer = () => {
  return (
    <>
      <div className="container">
        <hr className="section-split"></hr>
        <div className="row">
          <div className="col contactUs-left">
            <img src={footerLogo} alt="Footer logo"></img>
            <p>MOHD AZIM GENERAL TRADING Ltd.</p>
            <div className="contactUs-left-button-container">
              <Link
                to={{ pathname: "/form", state: "Demo Account" }}
                className="contactUs-left-button"
              >
                <button>Start a demo account</button>
              </Link>

              <a
                href="https://www.facebook.com/Magt-financials-112351584275316/"
                className="footer-facebook"
              >
                <img src={facebook} alt="Facebook"></img>
              </a>
            </div>
          </div>
          <div className="col ">
            <p className="contactUs-middle">Quick Links</p>
            <div className="contactUs-middle-links">
              <Link exact to="/" className="navBar-link">
                <span>{">"}</span> Home
              </Link>
              <Link exact to="/about-us" className="navBar-link">
                <span>{">"}</span> About Us
              </Link>
            </div>
          </div>
          <div className="col">
            <p className="contactUs-middle contactUs-contact-us">Contact Us</p>
            <div className="contactUs-right">
              <img
                src={emailIcon}
                alt="Email"
                className="contactUs-right-email"
              ></img>
              <p>support@mohdazimgeneraltrading.com</p>
            </div>
            <div className="contactUs-right">
              <img
                src={location}
                alt="Location"
                className="contactUs-right-location-one"
              ></img>
              <p>
                Offshore Investment and Legal Services Ltd. Ground Floor, The
                Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet, Saint
                Lucia.
              </p>
            </div>
            <div className="contactUs-right">
              <img
                src={location}
                alt="Location"
                className="contactUs-right-location-two"
              ></img>
              <p>
                Şerefefendi Sok. No:24, Altun Center İş Merkezi Teras Kat, Molla
                Fenari Mah., Fatih, Istanbul.
              </p>
            </div>
          </div>
        </div>
        <hr className="contactUs-line"></hr>
        <div className="row">
          <div className="col center">
            <p className="contactUs-disclosure">
              <span>Disclaimer:</span> Derivatives and CFDs are complex
              instruments and come with a high risk of losing money rapidly due
              to leverage. You should consider whether you understand how CFDs
              work and whether you can afford to take the high risk of losing
              your money. Information on this website should not be considered
              as an investment advice. Mohd Azim General Trading LTD is the
              property of and operated by Mohd Azim General Trading LTD,
              registered in Saint Lucia under license number 2023-00245.
              <br></br>
              <br></br>
              Mohd Azim General Trading does not provide services to residents
              of the following countries, including but not limited to:
              Afghanistan, American Samoa, Belarus, Burundi, Canada, Central
              African Republic, Congo-Brazzaville, Cuba, Côte d'Ivoire, Eritrea,
              European Union countries, Guinea, Iran, Iraq, Lebanon, Liberia,
              Libya, Mali, Myanmar, North Korea, Puerto Rico, Russia, Rwanda,
              Saint Vincent and the Grenadines, Somalia, Sudan, Syria, U.S.
              Virgin Islands, United States, Venezuela, Yemen, Zimbabwe,
              Türkiye.
            </p>
          </div>
        </div>
        <hr className="contactUs-line"></hr>
        <div className="row">
          <div className="col center">
            <p className="copyright">
              <span>©COPYRIGHT 2024</span> MOHD AZIM GENERAL TRADING
            </p>
            <p className="credits">
              DESIGNED AND DEVELOPED BY{" "}
              <a href="https://www.nexusgroupsolutions.com" target="_blank">
                www.nexusgroupsolutions.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import hero from "../../Assets/hero.png";
import heroMobile from "../../Assets/heroMobile.png";
import circularImage1 from "../../Assets/candles.png";
import circularImage2 from "../../Assets/future.png";
import circularImage3 from "../../Assets/money.png";
import bigCandles from "../../Assets/bigCandles.png";
import arrowIcon from "../../Assets/arrowIcon.png";
import metaTraderIcon from "../../Assets/metaTraderIcon.png";
// import metaTrader from "../../Assets/metatrader.png"
import "./Home.css";
import "./Home.mobile.css";

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

const Home = () => {
  ScrollToTopOnMount();

  return (
    <div className="container">
      <div className="row hero-row-space">
        <div className="col-md-7">
          <p className="hero-title">
            One Platform, Multiple <br></br>Investement Opportunities.
          </p>
          <p className="hero-subtitle">
            Put Your Online Trading Ideas <br></br>Into Action
          </p>
          <Link to={{ pathname: "/form", state: "Demo Account" }}>
            <button className="hero-button">Create a free account!</button>
          </Link>
        </div>
        <div className="col-md-5">
          <img src={hero} alt="hero" className="hero"></img>
          <img src={heroMobile} alt="hero" className="heroMobile"></img>
        </div>
      </div>
      <div className="row row-space">
        <div className="col center">
          <p className="yes-title">
            Welcome to the company that likes to say{" "}
            <span className="yes">Yes!</span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col center">
          <div className="circular-border">
            <p>
              <span>Yes,</span>
              <br></br>You can trade!
            </p>
            <img src={circularImage1} id="candles"></img>
          </div>
        </div>
        <div className="col center">
          <div className="circular-border">
            <p>
              <span>Yes,</span>
              <br></br>You can invest!<br></br>In your future
            </p>
            <img src={circularImage2}></img>
          </div>
        </div>
        <div className="col center">
          <div className="circular-border">
            <p>
              <span>Yes,</span>
              <br></br>Financial<br></br>Freedom is in<br></br>Your hands!
            </p>
            <img src={circularImage3}></img>
          </div>
        </div>
      </div>
      <div className="row row-space">
        <div className="col center col-">
          <p className="tradingWorld-title">
            We make the world of trading easy<br></br> and affordable for
            everyone
          </p>
          <p className="tradingWorld-text">
            Best decisions happen with a clear and educated mind. MOHD AZIM
            GENERAL TRADING
            <br></br>LTD paves the way for you. It makes your journey look
            easier and simpler
          </p>
          <p className="tradingWorld-subTitle">Honesty is our strength!</p>
          <div>
            <img src={arrowIcon} className="button-icon-left"></img>
            <Link to={{ pathname: "/form", state: "Real Account" }}>
              <button className="tradingWorld-button">
                Start investing with us!
              </button>
            </Link>

            <img src={arrowIcon} className="button-icon-right"></img>
          </div>
        </div>
        <hr className="line"></hr>
      </div>
      <div className="row row-space-2">
        <div className="col center">
          <p className="aboutUs-title">
            It is time for a better way to trade online
          </p>
          <p className="aboutUs-subtitle">
            You are the driver. We give you control
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <p className="aboutUs-text">
            MOHD AZIM GENERAL TRADING LTD Financials is a world of options. It
            is a trusted platform that makes online trading possible for
            everyone. It is committed to delivering the best quality. You can
            trade with a demo account or a real account… Like you own the place.
          </p>
        </div>
        <div className="col-md center">
          <img src={bigCandles} className="aboutUs-image "></img>
        </div>
      </div>
      <div className="row button-space">
        <div className="col-sm">
          <Link to={{ pathname: "/form", state: "Demo Account" }}>
            <button className="demo-account-button">
              Open a Demo Account immediately!
            </button>
          </Link>
        </div>
        <div className="col-sm">
          <Link to={{ pathname: "/form", state: "Real Account" }}>
            <button className="real-account-button">
              Open a Real Account immediately!
            </button>
          </Link>
        </div>
        <hr className="line"></hr>
      </div>
      <div className="row row-space-2">
        <div className="col center">
          <p className="download-title">
            You have the chance to perform technical<br></br>analysis and
            trading operations
          </p>
          <p className="download-subtitle">For Free!</p>
          {/* <p className="download-text">Download Now!</p>
          <img src={arrowIcon} className="download-icon"></img>
          <br></br>
          <a href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/mt5setup.exe?utm_source=mt5webterminal&utm_campaign=en.help.menu"><button className="download-button"><img src={metaTraderIcon} className="download-image"></img> MetaTrader <span>5</span></button></a> */}
        </div>
      </div>
    </div>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EmailForm.css";
import "./EmailForm.mobile.css";

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

const EmailForm = (props) => {
  ScrollToTopOnMount();

  // const localHost = "http://localhost:5000"
  const { state } = props.location;

  let defaultAccountType = "Demo Account";

  if (state) {
    defaultAccountType = state;
  }

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    accountType: defaultAccountType,
  });

  const { firstName, lastName, phoneNumber, email, accountType } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    try {
      const res = await axios.post("/api/emailForm", formData);
    } catch (err) {}
    document.getElementById("terms").checked = false;
  };

  return (
    <div className="container">
      <div className="row emailForm-space">
        <div className="col-sm"></div>
        <div className="col-sm-6">
          <form onSubmit={handleSubmit} className="emailForm">
            <input
              required
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={onChange}
              value={firstName}
              className="emailForm-first-name"
            ></input>
            <br></br>
            <input
              required
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={onChange}
              value={lastName}
              className="emailForm-last-name"
            ></input>
            <br></br>
            <input
              required
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              onChange={onChange}
              value={phoneNumber}
              className="emailForm-phone-number"
            ></input>
            <br></br>
            <input
              required
              type="email"
              name="email"
              placeholder="Enter email"
              onChange={onChange}
              value={email}
              className="emailForm-email"
            ></input>
            <br></br>
            <select
              name="accountType"
              defaultValue="Demo Account"
              onChange={onChange}
              value={accountType}
              className="emailForm-account-type"
            >
              <option value="Demo Account">Demo Account</option>
              <option value="Real Account">Real Account</option>
            </select>
            <br></br>
            <input
              required
              type="checkbox"
              id="terms"
              name="terms"
              className="emailForm-terms-checkbox"
            />{" "}
            I agree and understand that Mohd Azim General Trading has a
            legitimate interest in contacting me, in order to inform me about
            its products and services, or for other markting purposes. I also
            understand that I can opt out of receiving marketing communications
            and acquire further information on how my data will be processed.
            <br></br>
            <button type="submit" className="emailForm-button">
              Send Request
            </button>
          </form>
        </div>
        <div className="col-sm"></div>
      </div>
    </div>
  );
};

export default EmailForm;
